.voice-icon-container {
  border-radius: 50%; /* Makes it round */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17%;
  height: auto;
  aspect-ratio: 1 / 1;
}

.voice-icon {
  width: 30%;
}
