.idle-voice-visual {
  position: absolute;
  background: url("../../assets/idle_frame_voice.png") no-repeat center center;
  background-size: 100% 100%; /* Stretch image to fill the container */
  width: 75%;
  height: 83%;
}

.idle-voice-placeholder {
  position: absolute;
  width: 25%;
}

.idle-outer-frame {
  width: 100%;
  height: 100%;
  background: url("../../assets/idle_frame_outer.png") no-repeat center center;
  background-size: 100% 100%; /* Ensure the entire image fits without clipping */
  box-sizing: border-box; /* Ensure padding does not overflow container */
  overflow: hidden;
  display: flex;
}

.idle-inner-frame {
  margin-top: 4%;
  height: 87%;
  background: url("../../assets/idle_frame_inner.png") no-repeat center center;
  background-size: 100% 100%; /* Stretch image to fill the container */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
