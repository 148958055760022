@import "../template.scss";

.back-btn-container {
  width: 7%;
  position: absolute;
  aspect-ratio: 8 / 3 !important;
  top: 20px;
  left: 20px;
}

.back-btn-corner {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 52%; /* Primary control over size */
  aspect-ratio: 1 / 1;
  border-left: 5px solid white;
  border-top: 5px solid white;
  z-index: 1;
}

.back-btn {
  position: absolute !important;
  height: inherit !important;
  width: 100%;
  z-index: 2;
}

.back-btn-icon {
  width: 70%;
}

.button-list {
  height: 65%;
  width: 80%;
  overflow: visible;
}

.isuzu-btn-shape {
  clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 0% 100%);
}

.isuzu-btn-container {
  width: 100%;
  position: relative;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  overflow: visible;
  cursor: pointer;
  height: 13%;

  .isuzu-btn-not-selected {
    width: 87%;
    background: $Black2;
    opacity: 0.6;
  }
}

.isuzu-btn {
  background: $RedGradient;
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;
  color: #fff;
  height: 100%;
  text-shadow: 0px 0px 10px rgba(256, 256, 256, 0.6);
  border: none;
}

.idle-voice-btn {
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  bottom: 7%;
  margin-left: -0.4%; // To make it centered
  height: 10% !important;
}

.isuzu-btn-corner-top-left {
  position: absolute;
  top: -5px;
  left: -5px;
  height: calc(100% + 10px);
  aspect-ratio: 1 / 1;
  border-left: 5px solid white;
  border-top: 5px solid white;
  z-index: 1;
}

.isuzu-btn-corner-bottom-right {
  position: absolute;
  bottom: -5px;
  right: -5px;
  height: calc(100% + 10px);
  aspect-ratio: 1 / 1;
  border-right: 5px solid white;
  border-bottom: 5px solid white;
  z-index: 1;
}

.isuzu-btn-canned-response {
  height: 16% !important;
}

.btn-multiline-text-wrapper {
  width: 88%;
  text-align: left !important;
}

.voice-btn {
  width: 30% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
