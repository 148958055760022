$Black2: #484c47;
$Black3: #81867d;
$White: #fafafa;
$Grey1: #f2f2f2;
$Red: #ee3324;
$RedGradient: linear-gradient(90deg, #9a1d15, #d63b1f, #e55026);

// Global styles
body {
  background: black !important;
  color: white !important;
  font-size: 1.2rem !important; // Default for desktops and larger
}

.screen-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden; /* Prevent unwanted scrollbars */
}

.screen-container {
  aspect-ratio: 16 / 9 !important; /* Maintain 16:9 aspect ratio */
  width: 100%;
  height: auto;
  max-width: calc(100vh * (16 / 9)); /* Limit width to maintain 16:9 */
  max-height: calc(100vw * (9 / 16)); /* Limit height to maintain 16:9 */
  padding: 10px;
}
