.video-frame-container {
  position: relative;
  aspect-ratio: 16 / 9; /* Maintain aspect ratio of the frame */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* Center horizontally and vertically */
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.isuzu-video {
  position: absolute;
  top: 7%; /* Adjust based on frame design */
  left: 6%;
  width: 87%; /* Adjust to fit the inner area of the frame */
  height: 79%;
  object-fit: cover;
  z-index: 1;
}
